/* General sidebar styles */
.sidebar {
    transition: width 0.3s ease;
    background-color: #fff;
  }
  
  .sidebar-expanded {
    width: 250px;
  }
  
  .sidebar-collapsed {
    width: 80px;
  }
  
  .nav-link {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: #28a745; /* Light green */
    transition: background-color 0.2s ease;
  }
  
  .nav-link:hover {
    background-color: #e8f5e9; /* Light green background on hover */
    border-radius: 5px;
  }
  
  .nav-link i {
    font-size: 1.2rem;
  }
  
  /* Active link with darker green background */
  .nav-link.active {
    background-color: #1e7e34; /* Darker green for active state */
    color: #fff; /* White text for contrast */
    border-radius: 5px;
    font-weight: 700;
  }
  
  .nav-link.active i {
    color: #fff; /* White icon for contrast */
  }
  
  /* Chevron icon */
  .nav-link i.ms-auto {
    margin-left: auto;
  }

  .sidebar-logo{
    width: 80px;
    margin-top: 20px; /* Space from the top */
    margin-bottom: 20px; /* Space below the logo */
  }
  