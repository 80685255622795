.enrollment-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.image-column {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.image-column::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.full-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.form-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.7);
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  width: 90%;
  max-width: 750px;
  top: 50%;
  transform: translateY(-50%);
}

.progress-bar-center {
  display: flex;
  justify-content: center;
}

.card {
  max-width: 500px;
  width: 100%;
}
.large-screen-image {
  display: block;
}

.small-screen-image {
  display: none;
}

/* Adjustments for smaller devices (tablets and below) */
@media (max-width: 768px) {
  .large-screen-image {
    display: none;
  }
  .small-screen-image {
    display: block;
  }

  .form-box {
    width: 90%;
    padding: 15px;
  }

  .image-column {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }

  .full-image {
    height: 100%;
  }

  .card {
    width: 100%;
    max-width: 400px;
  }
}

/* Further adjustments for mobile devices */
@media (max-width: 480px) {
  .form-box {
    width: 95%;
    padding: 10px;
  }
  .image-column {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }

  .full-image {
    height: 100%;
    object-fit: cover;
  }

  .card {
    max-width: 350px;
  }
}
