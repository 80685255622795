.card-number {
    background-color: #f4f4f4; /* Light background for cards */
    border: 1px solid #d1d1d1; /* Border color */
    border-radius: 8px; /* Rounded corners */
    padding: 15px; /* Reduced padding */
    /* display: flex; */
    align-items: center;
    height: 100px; /* Fixed height for consistency */
    overflow: hidden; /* Hide any overflowing content */
  }
  
  .card-icon-number {
    color: #28a745; /* Green color for icons */
    font-size: 2rem; /* Adjust icon size if needed */
  }
  
  .card-content-number {
    margin-left: 15px;
  }
  
  .card-title-number {
    font-size: 1rem; /* Reduced title font size */
    color: #333; /* Dark text color */
  }
  
  .card-value-number {
    font-size: 1.25rem; /* Adjust value font size */
    color: #28a745; /* Green color for values */
  }
  