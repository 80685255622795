/* Main content styling */
.main-content {
  background-color: #e9f7ef; /* Light green background */
  border-radius: 8px;
  padding: 20px;
  margin-left: 250px; /* Adjusted for sidebar */
  min-height: 100vh; /* Ensure full height for content */
}

/* Heading styles */
h1 {
  color: #28a745; /* Green color for headings */
}

/* Sidebar styling */
.sidebar {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  border-right: 1px solid #ddd;
  background-color: #fff; /* Light background for sidebar */
  transition: width 0.3s ease;
}

/* Navbar dropdown */
.navbar .dropdown-menu {
  min-width: 150px;
}

.navbar .dropdown-toggle img {
  border: 2px solid #e8f5e9; /* Light border around the avatar */
}

/* Row reset */
.row {
  margin: 0;
}

/* Card styling */
.card {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 15px;
  height: 120px; /* Reduced height for a sleeker look */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for cards */
  margin-bottom: 20px; /* Spacing between cards */
}

/* Card icon container */
.card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9ecef; /* Light background for icons */
  border-radius: 50%;
  padding: 10px;
  width: 60px;
  height: 60px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Card content section */
.card-content {
  margin-left: 15px;
}

/* Card title styling */
.card-title {
  font-size: 1.15rem;
  margin-bottom: 5px;
  color: #28a745; /* Green text */
}

/* Card value styling */
.card-value {
  font-size: 1.4rem;
  font-weight: bold;
}

/* Specific adjustments for green theme */
.main-content {
  background-color: #f8f9fa;
  min-height: 100vh;
}

.sidebar-expanded {
  width: 250px;
}

.sidebar-collapsed {
  width: 80px;
}

.nav-link {
  color: #28a745; /* Light green for links */
}

.nav-link.active {
  background-color: #1e7e34; /* Darker green */
  color: white !important;
  border-radius: 5px;
}

.nav-link.active i {
  color: white !important;
}

